const supplierReconciliation = {
  state: {
    pageType: 'list',
    beginUpdate: new Date(),
    publisherId: null,
    companyId: null,
    startDate: '',
    endDate: '',
    serviceType: 1, // 服务对象，1：业主，2：代理商
    contractQuery: {},
    agentBillList: []
  },
  mutations: {
    set_supplierReconciliation_serviceType (state, data) {
      state.serviceType = data
    },
    set_supplierReconciliation_pageType (state, data) {
      state.pageType = data
    },
    set_supplierReconciliation_update (state, data) {
      state.beginUpdate = data
    },
    set_supplierReconciliation_publisherId (state, data) {
      state.publisherId = data
    },
    set_supplierReconciliation_companyId (state, data) {
      state.companyId = data
    },
    set_supplierReconciliation_startDate (state, data) {
      state.startDate = data
    },
    set_supplierReconciliation_endDate (state, data) {
      state.endDate = data
    },
    set_supplierReconciliation_contractQuery (state, data) {
      state.contractQuery = data
    },
    set_supplierReconciliation_agentBillList (state, data) {
      state.agentBillList = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default supplierReconciliation
