<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent" ></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent" ></component>
      </div>
      <div
        slot="bottom_box_content"
        style="padding:10px 10px;"
      >
        <component
          :is="curBottomComponent"
          :publisherId="publisherId"
          :companyId="companyId"
          :detailQuery ="detailQuery"
          @on-showDetail="showDetail"
        ></component>
      </div>
      <div slot="bg_map"></div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
import supplierReconciliationStoreModule from '@/store/modules/supplierReconciliation'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    // 底部
    List: () => import('@/components/reconciliation/supplier/IndexTable'),
    Detail: () => import('@/components/reconciliation/supplier/Detail'),

    Right: () => import('@/components/reconciliation/supplier/Summary'),
    Left: () => null
  },
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      companyId: this.$store.getters.token.userInfo.companyId,
      detailQuery: {}
    }
  },
  watch: {
    pageType (val) {
      // 清空便签项
      this.setLinkTagArray()

      if (val === 'detail') {
        this.setBottomComponent('Detail')
        this.setRightComponent('Right')
        this.setShowRightBlock(true)

        this.setLinkTagArray({ key: 'reconciliationList', value: '供应商对账簿' })
        this.setLinkTagArray({ key: 'reconciliationDetail', value: '对账簿详情' })
        this.setActivedTagName('reconciliationDetail')
      } else {
        this.setPageDefaultComponent()
      }
      this.initTagEvent()
    }
  },
  created () {
    if (!this.$store.hasModule('supplierReconciliation')) {
      this.$store.registerModule('supplierReconciliation', supplierReconciliationStoreModule)
    }
  },
  methods: {
    initTagEvent () {
    // 注册头部事件
      this.setLinkRouterMaps(['reconciliationList', () => {
        this.$store.commit('set_supplierReconciliation_pageType', 'list')
      }])
    },
    setPageDefaultComponent () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setBottomComponent('List')
      this.setLinkTagArray({ key: 'reconciliationList', value: '供应商对账簿' })
      this.setActivedTagName('reconciliationList')
    },
    showDetail (query) {
      this.detailQuery = query
      this.$store.commit('set_supplierReconciliation_pageType', 'detail')
    }
  },
  computed: {
    pageType () {
      return this.$store.state.supplierReconciliation ? this.$store.state.supplierReconciliation.pageType : ''
    }
  },
  destroyed () {
    // 卸载reconciliation动态store模块
    this.$store.unregisterModule('supplierReconciliation')
    this.setShowLeftBlock(false)
    this.setShowMap(false)
    this.setShowRightBlock(false)
    this.setBottomHeight('90%')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
